<template>
  <router-view /> 
</template>

<script>

export default {
  name: 'App',
  components:{
   
  },
  data(){
    return {
      
    }
  },
  methods: {
  
  },
  mounted(){     
    
  }
}
</script>